// Bootstrap
@import "theme/bootstrap";

// Fonts and icons
@import "../fonts/icon-font/css/style";
@import "../fonts/fontawesome-5/css/all";

// Plugins
@import "../plugins/aos/aos.min";
@import "../plugins/fancybox/jquery.fancybox.min.css";
@import "../plugins/nice-select/nice-select.min.css";
@import "../plugins/slick/slick.min.css";
@import "../plugins/ui-range-slider/jquery-ui";
@import "../../node_modules/quill/dist/quill.snow.css";
@import "../../node_modules/select2/src/scss/core";
@import "toastr";